import { Form, Input, message, Row } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { signupAPI } from "../../apis/auth/AuthAPIs";
import { fetchCountriesAPI } from "../../apis/static/StaticAPIs";
import AppButton from "../../components/Button/Button";
import CommonDropdown from "../../components/Dropdown/Dropdown";
import ImageInput from "../../components/ImageInput/ImageInput";
import CommonTextInput from "../../components/Input/Input";
import { constRoute } from "../../utils/Constants";
import { validatePhone } from "../../utils/helpers/GeneralHelpers";
import "./Signup.css";
import EyeClosedIcon from "../../assets/close-eye-icon.svg";
import EyeOpenedIcon from "../../assets/open-eye-icon.svg";
import { handleError, requestErrorHandel } from "../../utils/common-functions";

const Signup = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [countries, setCountries] = useState([]);
  const [businessLicense, setBusinessLicense] = useState(undefined);
  const [photo, setPhoto] = useState(undefined);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [passwordStaric, setPasswordStaric] = useState("");

  const fetchCountries = async () => {
    try {
      const result = await fetchCountriesAPI();
      if (result.data.statusCode === 200) {
        let countryList = result.data.data;
        countryList = countryList.map((country) => {
          return {
            value: country.id,
            label: country.name,
          };
        });
        setCountries(countryList);
      } else throw result;
    } catch (e) {
      console.log("errror in fetch country", e);
      setCountries([]);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const goToSignIn = () => navigate("/login");

  const onChangeNo = (value) => {
    form.setFieldValue("phoneNo", value);
    setPhoneNumber(value);
    validatePhone(value)
      ? form.setFields([
          {
            name: "phoneNo",
            errors: [""],
          },
        ])
      : form.setFields([
          {
            name: "phoneNo",
            errors: [t(`validations.invalidPhoneNumber`)],
          },
        ]);
  };

  const onSignup = async (values) => {
    if (!validatePhone(values.phoneNo)) {
      form.setFields([
        {
          name: "phoneNo",
          errors: [t(`validations.invalidPhoneNumber`)],
        },
      ]);
      return;
    }
    try {
      const data = {
        ...values,
        phoneNo: "+" + values?.phoneNo,
        countryId: Number(values.countryId),
        photo: photo ?? "",
        license: businessLicense ?? "",
      };
      setLoading(true);
      const result = await signupAPI(data);
      setLoading(false);
      if (result.data.statusCode === 200) {
        navigate("/verifyOTP", {
          state: {
            phoneNo: values.phoneNo,
            phoneToken: result.data.data.phoneToken,
          },
        });
      } else throw new Error(result.data.message);
    } catch (e) {
      setLoading(false);
      // handleError(e, messageApi);
      requestErrorHandel(e, navigate);
    }
  };

  const handleChange = (e) => {
    setPasswordValue(e.target.value);
    form.setFieldsValue({
      password: e.target.value,
    });
    let element = "";
    for (let i = 0; i < e.target.value?.length; i++) {
      element = element + "*";
    }
    setPasswordStaric(element);
  };

  return (
    <div className="signup-main">
      {contextHolder}
      <Form
        className="signup-form"
        form={form}
        layout={"vertical"}
        onFinish={onSignup}
        autoComplete={"newpassword"}
      >
        <h2 className="bold__font">{t(`signup.signup`)}</h2>
        <br />
        <Form.Item
          name={"name"}
          rules={[
            {
              required: true,
              message: t("validations.requiredFullName"),
            },
          ]}
        >
          <CommonTextInput placeholder={t(`signup.fullName`)} />
        </Form.Item>
        <Form.Item
          name={"email"}
          rules={[
            {
              validator: (_, value) => {
                const regex =
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (value && regex.test(value.toLowerCase())) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    new Error(t("validations.requiredEmail"))
                  );
                }
              },
            },
          ]}
        >
          <CommonTextInput placeholder={t(`signup.email`)} />
        </Form.Item>
        <Form.Item
          name={"countryId"}
          rules={[
            {
              required: true,
              message: t("validations.requiredCountry"),
            },
          ]}
        >
          <CommonDropdown
            data={countries}
            placeholder={t("signup.selectCountry")}
            required
            showSearch
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase())
            }
          />
        </Form.Item>

        <Form.Item
          name={"phoneNo"}
          rules={[
            {
              required: true,
              message: t("validations.requiredPhoneNumber"),
            },
          ]}
        >
          <CommonTextInput
            type={"phone"}
            placeholder={t("signup.phoneNumber")}
            value={phoneNumber}
            setValue={onChangeNo}
            required
          />
        </Form.Item>
        <p className="whatsapp_caption">{t("validations.whatsAppCaption")}</p>
        <Form.Item
          name={"companyName"}
          // rules={[{ required: true, message: t("validations.companyName") }]}
        >
          <CommonTextInput
            placeholder={t(`signup.pleaseEnterCompanyName`)}
            className="input"
          />
        </Form.Item>
        <Form.Item
          name={"businessAddress"}
          rules={[
            { required: true, message: t("validations.requiredBusinessTitle") },
          ]}
        >
          <CommonTextInput
            placeholder={t(`signup.pleaseEnterBusinessAddress`)}
            className="input"
          />
        </Form.Item>
        {/* <div style={{ position: "relative" }}>
          {visible ? (
            <>
              <p className={"pass-staric"}> {passwordStaric} </p>
              <img
                className={`pass-img ${i18n.language}`}
                onClick={() => {
                  form.setFieldsValue({
                    password: passwordValue,
                  });
                  setVisible(false);
                }}
                src={EyeClosedIcon}
                alt={"hide password"}
              />
            </>
          ) : (
            <img
              className={`pass-img ${i18n.language}`}
              onClick={() => {
                form.setFieldsValue({
                  password: passwordValue,
                });
                setVisible(true);
              }}
              src={EyeOpenedIcon}
              alt={"show password"}
            />
          )}

          <Form.Item
            name={"password"}
            rules={[
              {
                required: true,
                message: t("validations.requiredPassword"),
              },
              {
                min: 8,
                message: t("validations.requiredMinPassword"),
              },
            ]}
          >
            <Input
              placeholder={t("signup.password")}
              // required
              visible={visible}
              onChange={handleChange}
              className={`common-input ${visible && "invisible-input"}`}
            />
          </Form.Item>
        </div> */}
        <div style={{ position: "relative" }}>
          {visible ? (
            <>
              {/* <p className={"pass-staric"}>
                {" "}
                {form.getFieldValue("password")}{" "}
              </p> */}
              <img
                className={`pass-img ${i18n.language}`}
                onClick={() => setVisible(false)}
                src={EyeOpenedIcon}
                alt={"hide password"}
              />
            </>
          ) : (
            <img
              className={`pass-img ${i18n.language}`}
              onClick={() => setVisible(true)}
              src={EyeClosedIcon}
              alt={"show password"}
            />
          )}

          <Form.Item
            name={"password"}
            rules={[
              {
                required: true,
                message: t("validations.requiredPassword"),
              },
              {
                min: 8,
                message: t("validations.requiredMinPassword"),
              },
            ]}
          >
            <Input
              type={visible ? "text" : "password"}
              placeholder={t("signup.password")}
              onChange={handleChange}
              className="common-input"
            />
          </Form.Item>
        </div>
        <ImageInput
          placeholder={t("signup.uploadBusinessLicense")}
          className={"image-input-width"}
          value={businessLicense}
          onSelect={setBusinessLicense}
          setUploading={setLoading}
          buttonText={t(`signup.upload`)}
        />
        <ImageInput
          placeholder={t("signup.uploadPhoto")}
          className={"image-input-width"}
          value={photo}
          onSelect={setPhoto}
          setUploading={setLoading}
          buttonText={t(`signup.upload`)}
        />
        <Row align={"top"}>
          <p>{t(`signup.byContinuing`)}</p>{" "}
          <p
            className={"create-account-btn bold__font t-red t-underline"}
            onClick={() => navigate(constRoute.termsAndConditions)}
          >
            {t(`signup.termsConditions`)}
          </p>
        </Row>
        <AppButton
          type="primary"
          className={"sign-in-btn"}
          htmlType={"submit"}
          loading={loading}
        >
          {t(`signup.signup`)}
        </AppButton>
        <Row align={"top"}>
          <p>{t(`signup.alreadyAccount`)}</p>{" "}
          <p
            className={"create-account-btn bold__font t-red"}
            onClick={goToSignIn}
          >
            {t(`login.sign-in`)}
          </p>
        </Row>
      </Form>
    </div>
  );
};

export default Signup;
